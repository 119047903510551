<template>
  <div style="background-color: #f5f7f9">
    <div class="projectHeader">
      <Breadcrumb
        style="
          float: left;
          line-height: 2rem;
          font-size: 1.25rem;
          margin-left: 1rem;
        "
      >
        <a style="font-weight: 700" :href="'/#/projectDetail?id=' + projectId"
          >◀ {{ projectName }}</a
        >
      </Breadcrumb>
    </div>
    <div
      style="
        width: 100%;
        float: left;
        background-color: white;
        min-height: 100vh;
      "
    >
      <div style="width: 25%; float: left; padding: 20px 4%">
        <div style="width: 100%; float: left; margin-top: 35px">
          <Avatar v-if="faceUrl === ''" icon="ios-person" size="100" />
          <Avatar v-if="faceUrl !== ''" :src="faceUrl" size="100" />
        </div>
        <div style="width: 100%; float: left; margin-top: 25px">
          {{ userName }}
        </div>
        <Card
          style="width: 100%; float: left; padding: 5% 20px; margin-top: 30px"
        >
          <div style="text-align: left">
            <p style="display: inline; font-weight: 500; font-size: 1.025rem">
              Your credit:
            </p>
            <p style="display: inline">{{ creditPoint }}</p>
          </div>
        </Card>
        <div style="width: 100%; float: left; padding-top: 50px">
          <div class="discription">
            <h2 class="disTitle">Tips</h2>
            <div style="width: 100%; float: left" class="disContent">
              You can report an illegal or unacceptable activity.
            </div>
            <div
              style="
                width: 60%;
                margin-top: 30px;
                margin-left: 20%;
                float: left;
                margin-bottom: 30px;
              "
            >
              <Button long type="primary" @click="report">Report</Button>
              <Modal
                v-model="modal3"
                ok-text="OK"
                cancel-text="Cancel"
                title="Report"
                @on-ok="submitReport"
                :loading="true"
              >
                <div>
                  <div>
                    <p style="display: inline; font-size: 1.025rem">Project:</p>
                    {{ projectName }}
                  </div>
                  <div style="padding-top: 5px">
                    <p style="font-size: 1.025rem">Specific Reason：</p>
                    <div>
                      <span style="color: darkgray"
                        >You should describe the situation you meet.</span
                      >
                      <Input v-model="report_detail" type="textarea"> </Input>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <Divider />
        </div>
      </div>
      <div style="width: 70%; float: left">
        <div style="width: 100%; float: left; text-align: left">
          <div
            style="
              padding-top: 20px;
              width: 80%;
              float: left;
              font-weight: 700;
              font-size: 1.3rem;
            "
          >
            Join this project
            <Divider />
          </div>
          <div style="padding-top: 0px; width: 100%; float: left">
            <div style="width: 100%; float: left">
              <div style="font-weight: 500; font-size: 1.1rem; display: inline">
                Project:
              </div>
              <span class="CWords" style="display: inline">{{
                projectName
              }}</span>
            </div>
            <div style="width: 96%; float: left; margin-top: 10px">
              <div
                style="
                  font-weight: 500;
                  font-size: 1.1rem;
                  margin-top: 20px;
                  display: inline;
                "
              >
                Abstract:
              </div>
              <span class="CWords" style="display: inline">{{ abstract }}</span>
            </div>
          </div>
          <div style="width: 100%; float: left">
            <Menu mode="horizontal" active-name="1" @on-select="menuSelect">
              <MenuItem name="1">
                <Icon type="ios-stats" />
                Join request
              </MenuItem>
              <!--                            <MenuItem v-if="application_count === 1 && application_status === 'pass'" name="2">-->
              <!--                                <Icon type="ios-people"/>-->
              <!--                                Test Case-->
              <!--                            </MenuItem>-->
              <!-- <MenuItem name="3">
                                <Icon type="md-bulb" />
                                Information request
                            </MenuItem>-->
            </Menu>
          </div>
        </div>

        <router-view></router-view>
      </div>
      <div style="width: 0%; float: left"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "createJoin",
  data() {
    return {
      creditPoint: 0,
      modal3: false,
      faceUrl: "",
      userName: "777",
      projectName: "",
      projectId: "",
      userId: "",
      abstract: "",
      disabled: false,
      read_tag: false,
      report_detail: "",
      myChart: "",
      environment: "",
      application_count: 0,
      application_status: "",
      applicationNum: 0,
    };
  },
  methods: {
    Initial_data() {
      //获取用户信息，获取项目信息
      //判断当前userId + projectId 来看当前项目下的Join情况
      var Tself = this;
      this.projectId = this.$route.query.id;
      this.userId = Tself.getCookie("user_id");
      this.userName = Tself.getCookie("user_name");
      this.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/project/findById",
        data: this.projectId,
      })
        .then(function (response) {
          var data = response.data.data.items;
          Tself.environment = data.environment;
          Tself.projectName = data.name;
          Tself.abstract = data.abstractInfo;
          Tself.languages = data.languages;
          Tself.dependencies = data.dependencies;
          Tself.creationTime = data.creationTime;
          Tself.applicationNum = data.count;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$axios
        .post("/user/findById", this.getCookie("user_id"), {
          headers: {
            "Content-Type": "text/xml",
          },
        })
        .then((response) => {
          let uf = response.data.data.items;
          Tself.userName = uf.username;
          Tself.name = uf.username;
          Tself.faceUrl = uf.faceUrl;
          Tself.creditPoint = uf.credit;
        })
        .catch((error) => {
          console.log(error);
        });

      var userJoinVO = {
        projectId: this.projectId,
        userId: this.userId,
      };

      this.$axios({
        method: "post",
        url: "/join/findByUserIdAndProjectId",
        data: userJoinVO,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          var data = response.data.data.items;
          this.application_count = data.count; //1
          this.application_status = data.status; //pass
        })
        .catch((error) => {
          console.log("You should commit a join application.");
          this.application_count = 0;
          this.application_count = "";
        });
    },
    ok() {
      this.applicationNum = 1;
    },
    cancel() {},
    report() {
      this.modal3 = true;
    },
    reset() {
      this.formLeft.description = "";
      this.formLeft.input_type = "";
      this.formLeft.return_type = "";
      this.formLeft.functionPoint = "";
      this.formLeft.rate = 0;
    },
    join_check() {},
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    joinClick() {
      this.$router.push("/#/project?id=" + this.projectId);
    },
    menuSelect(name) {
      console.log("name: " + name);
      switch (name) {
        case "1":
          location.href = "/#/createJoin1?id=" + this.projectId;
          break;
        case "2":
          location.href = "/#/createJoin2?id=" + this.projectId;
          break;
        case "3":
          location.href = "/#/createJoin3?id=" + this.projectId;
          break;
      }
    },
    async submitReport() {
      let arbitration = {
        projectId: this.projectId,
        projectName: this.projectName,
        reason: this.report_detail,
        sourceId: this.userId,
        sourceType: "user",
        targetId: this.projectId,
        targetType: "project",
      };
      if (!this.report_detail) {
        this.$Message.warning("Please discribe your reason.");
        return;
      }
      console.log(arbitration);
      try {
        let res = await this.$axios({
          method: "post",
          url: "/arbitration/addArbitration",
          data: arbitration,
        });
        console.log(res);

        this.modal3 = false;
        this.$Message.success("success");
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.Initial_data();
  },
  updated() {},
};
</script>

<style scoped>
.join_main_container {
  width: 90%;
  margin-left: 5%;
  float: left;
  background-color: white;
}

.projectHeader {
  margin: 20px 0;
  width: 100%;
  height: 2rem;
  padding: 0 3.125rem;
  float: left;
}
.join_table_unit {
  padding-bottom: 40px;
}
</style>
